import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, MediumIcon, CertikIcon, HomeIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  // {
  //   label: "About",
  //   items: [
  //     {
  //       label: "MapleSales",
  //       href: "https://www.maplesales.org/",
  //     },
  //     {
  //       label: "MapleNFT",
  //       href: "https://nft.maplesales.org/",
  //     },
  // {
  //   label: "Community",
  //   href: "https://docs.bitchain.biz/contact-us/telegram",
  // },
  // {
  //   label: "CAKE",
  //   href: "https://docs.bitchain.biz/tokenomics/cake",
  // },
  // {
  //   label: "—",
  // },
  // {
  //   label: "Online Store",
  //   href: "https://pancakeswap.creator-spring.com/",
  //   isHighlighted: true,
  // },
  //   ],
  // },
  {
    label: "About",
    items: [
      {
        label: "Explorer",
        href: "https://explorer.bitchain.biz/",
      },
      // {
      //   label: "Bridge",
      //   href: "https://newbridge.bitchain.biz/",
      // },
      // {
      //   label: "Guidebook",
      //   href: "https://introduce.5bitchain.com/",
      // }
      // {
      //   label: "TX SWAP V1",
      //   href: "https://v1.txpocket.link/",
      // },
    ],
  },
];

// 修改点：左下角的社交链接. 本文件只能由专属分支提交，非dev分支
export const socials = [
  {
    label: "官网",
    icon: HomeIcon,
    href: "https://kekecoin.xyz",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/KEKE_BSC",
  },
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://x.com/bsckeke",
  },
  {
    label: "Certik",
    icon: CertikIcon,
    href: "https://skynet.certik.com/zh-CN/projects/bitswap",
  }
];

// export const socials = [
//   {
//     label: "Twitter",
//     icon: TwitterIcon,
//     href: "https://twitter.com/maplesale_cn",
//   },
//   {
//     label: "Telegram",
//     icon: TelegramIcon,
//     href: "https://t.me/maplesalee",
//   },
//   {
//     label: "Reddit",
//     icon: RedditIcon,
//     href: "https://reddit.com/r/pancakeswap",
//   },
//   {
//     label: "Instagram",
//     icon: InstagramIcon,
//     href: "https://instagram.com/pancakeswap_official",
//   },
//   {
//     label: "Github",
//     icon: GithubIcon,
//     href: "https://github.com/pancakeswap/",
//   },
//   {
//     label: "Discord",
//     icon: DiscordIcon,
//     href: "https://discord.gg/pancakeswap",
//   },
//   {
//     label: "Medium",
//     icon: MediumIcon,
//     href: "https://medium.com/pancakeswap",
//   },
// ];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
